import { setJWTToken, unsetJWTToken } from "./login";
import { removeAuthDataFromLocalStorage, saveAuthDataToLocalStorage } from "./localStorage";

export const loginUser = (id, email, firstName, lastName, token) => {
	saveAuthDataToLocalStorage(id, email, firstName, lastName, token);
	setJWTToken(token);
};
export const logoutUser = () => {
	removeAuthDataFromLocalStorage();
	unsetJWTToken();
};
