import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { navigate } from 'gatsby';
import { useDispatch } from 'react-redux';
import SEO from '../../components/seo';
import LoginDrawingSVG from '../../icons/login/step3.svg';
import { API_LINKS, LINKS } from '../../constants/links';
import { isNotBuildPhase, isUserLogged } from '../../utils/localStorage';
import { getCookiesBeforeLogin, setLoginRedirected } from '../../utils/cookies';
import { authorizeTestAction, logoutUserAction } from '../../state/user.reducer';
import { logoutUser } from '../../utils/auth';
import { clearLayoutAction, setLayoutAction } from '../../state/layout.reducer';
import { AppContainer } from '../../components/common/appContainer';

const StyledPageWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin-top: -80px;
	min-height: calc(100vh);
	overflow: hidden;
	padding-top: 30px;
`;
const StyledSlide = styled.div`
	align-items: center;
	display: flex !important;
	flex-direction: column;
	justify-content: center;
	padding: 30px 0;
	text-align: center;

	svg {
		height: 120px;
		@media (min-height: 600px) {
			height: 170px;
		}
	}
`;
const StyledSlideHeader = styled.h2`
	color: ${({ theme }) => theme.colors.brandPurple};
	font-size: 1.3rem;
	font-weight: 900;
	line-height: 2.1rem;
	margin: 0;
	padding: 0;
	text-align: center;
`;
const StyledSlideText = styled.p`
	color: ${({ theme }) => theme.colors.fontBlack};
	font-size: 1.6rem;
	font-weight: 300;
	line-height: 2rem;
	margin: 20px 0 0;
	max-width: 280px;
	padding-top: 15px;
	@media (max-width: 330px) {
		margin: 0;
	}
`;
const Styled4rdPartyLoginButtonsWrapper = styled.div`
	padding-bottom: 30px;
`;
const Styled3rdPartyLoginButton = styled.button`
	border: none;
	border-radius: 18px;
	color: #fff;
	display: block;
	font-weight: 700;
	line-height: 35px;
	margin: 0;
	outline: none;
	padding: 0;
	width: 100%;

	&.fb {
		background-color: #3c5a99;
		margin-bottom: 11px;
	}

	&.google {
		background-color: #bb0e23;
	}

	&.test {
		background-color: #ff8009;
		margin-bottom: 11px;
	}
`;
const StyledSkipSignIn = styled.a`
		// color: ${({ theme }) => theme.colors.fontGray};
	color: ${({ theme }) => theme.colors.brandPurple};
	display: block;
	font-size: 1.1rem;
	line-height: 1.1rem;
	margin-top: 15px;
	text-align: center;
	text-decoration: underline;
`;

const LoginPage = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [paymentState, setPaymentState] = useState(false);
	const { orderId, price, paymentType } = getCookiesBeforeLogin();
	const isNotProduction =		process.env.GATSBY_REST_PAY_ENV === 'development'
		|| process.env.GATSBY_REST_PAY_ENV === 'staging'
		|| process.env.GATSBY_REST_PAY_ENV === 'test';

	if (orderId && price && paymentType && !paymentState) {
		setPaymentState(true);
	}

	if (isUserLogged()) {
		logoutUser();
		dispatch(logoutUserAction());
	}

	const onAuthorizeTest = () => {
		if (!isNotProduction) {
			return true;
		}
		dispatch(authorizeTestAction());
	};

	const onAuthorizeGoogle = () => {
		if (isNotBuildPhase) {
			window.location.replace(API_LINKS.AUTHORIZE_GOOGLE);
		}
	};

	const onAuthorizeFacebook = () => {
		if (isNotBuildPhase) {
			window.location.replace(API_LINKS.AUTHORIZE_FACEBOOK);
		}
	};

	const skipSignIn = () => {
		setLoginRedirected();
		navigate(LINKS.HOMEPAGE);
	};

	useEffect(() => {
		dispatch(setLayoutAction({ withoutHeader: true }));

		return () => {
			dispatch(clearLayoutAction());
		};
	}, []);

	return (
		<>
			<SEO title={t('login.title')} />
			<AppContainer>
				<StyledPageWrapper>
					<StyledSlideHeader>
						{t('login.firstLine')}
						<br />
						{t('login.secondLine')}
					</StyledSlideHeader>
					<StyledSlide>
						<LoginDrawingSVG />
						<StyledSlideText>{t('login.thirdLine')}</StyledSlideText>
					</StyledSlide>
					<Styled4rdPartyLoginButtonsWrapper>
						{isNotProduction && (
							<Styled3rdPartyLoginButton
								className="test"
								onClick={onAuthorizeTest}
							>
								{t('login.loginTestAccount')}
							</Styled3rdPartyLoginButton>
						)}
						<Styled3rdPartyLoginButton
							className="fb"
							onClick={onAuthorizeFacebook}
						>
							{t('login.loginFacebook')}
						</Styled3rdPartyLoginButton>
						<Styled3rdPartyLoginButton
							className="google"
							onClick={onAuthorizeGoogle}
						>
							{t('login.loginGoogle')}
						</Styled3rdPartyLoginButton>
						<StyledSkipSignIn onClick={skipSignIn}>{t('login.skipSignIn')}</StyledSkipSignIn>
					</Styled4rdPartyLoginButtonsWrapper>
				</StyledPageWrapper>
			</AppContainer>
		</>
	);
};

export default LoginPage;
